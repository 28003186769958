import { MembersAreaAppsOptions, IntegrationApplication } from '@wix/members-area-integration-kit';
import { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { getAppDefinitions } from '@wix/members-area-app-definitions';
import { runAndWaitForApproval } from '../wrappers/transactions';
import * as appState from '../services/applicationState';
import enforceSequentiality from '../enforceSequentiality';
import * as applicationState from '../applicationState';
import { log, toMonitored } from '../../utils/monitoring';
import { addApplications, createConnectionConfigs } from '../platform-api/addApplications';
import * as routersWrapper from '../wrappers/routers';
import * as pagesService from '../services/pages';
import { addMinHeight } from '../services/pages';
import { getTranslationFunction } from '../../i18n';
import * as pagesWrapper from '../wrappers/pages';
import * as routersService from '../services/routers';
import { removeMembersAreaPageByPageId } from '../platform-api/removeMembersAreaPage';
import { setHorizontalLayout, setSidebarLayout } from '../platform-api/layouts';
import * as membersIntegrationApi from '../services/integration';
import { getProfileType, ProfileType, setProfileType } from '../services/profile';
import { refreshMembersAreaApps } from '../services/members-area';
import { BiData } from '../../types/EditorAppModule';

const getUniqueNewPublicPageUriSeo = async (editorSDK: EditorSDK, initialPrefix = 'blank') => {
  let pageUriSeo;

  const routers = await routersWrapper.getAll(editorSDK);
  const currentPageUris = Object.keys(
    routers.find((router) => router.config.type === 'public')?.config.patterns || {},
  ).map((pattern) => pattern.split('/{userName}/')[1]);

  let counter = 1;
  let isUnique;

  do {
    pageUriSeo = `${initialPrefix}-${counter}`;
    isUnique = !currentPageUris.includes(pageUriSeo);
    counter++;
  } while (!isUnique && counter < 1000);

  return pageUriSeo;
};

const createBlankPage = async (editorSDK: EditorSDK, isPrivate = true) => {
  let pageRef;

  try {
    const t = await getTranslationFunction(editorSDK);
    const pageUriSEO = isPrivate ? undefined : await getUniqueNewPublicPageUriSeo(editorSDK);
    const pageTitle = isPrivate ? t('Pages_New_Private_Page_Title') : t('Pages_New_Public_Page_Title');
    pageRef = await pagesWrapper.addPage({ editorSDK, pageTitle, pageUriSEO });
    await addMinHeight([{ pageRef }], editorSDK);
    if (!isPrivate) {
      await pagesWrapper.updatePageData({
        editorSDK,
        pageRef,
        pageData: { pageSecurity: { requireLogin: false }, hidePage: false },
      });
    }

    const createdPage: PageData & { pageRef?: PageRef } = await pagesWrapper.getPageData({ editorSDK, pageRef });
    const routers = (await routersService.getMembersAreaRouters(editorSDK)) || { publicRouter: {}, privateRouter: {} };
    createdPage.pageRef = pageRef;
    const apps = [
      {
        appDefinitionId: createdPage.managingAppDefId,
        pageId: '',
        social: !isPrivate,
        showInLoginMenu: true,
        showInMemberMenu: true,
        loginMenuTitle: false,
      },
    ];
    // TODO: Possible issue - applications and apps type mismatch
    // @ts-ignore
    const connectionConfigs = createConnectionConfigs({ applications: apps, pages: [createdPage], routers });
    await pagesService.connectPagesToMembers({ editorSDK, pages: connectionConfigs });
    await pagesService.setStateForPages(editorSDK);
  } catch (e) {
    log('Add custom page failed', {
      tags: { reason: e.toString() + '\n' + e.stack, isPrivate, pageRefAdded: !!pageRef },
    });
  }
};

const maybeAddApplications = async (
  applications: IntegrationApplication[],
  shouldNavigate: boolean,
  options: { biData: BiData },
) => {
  const editorSDK = appState.getEditorSDK();
  if (!editorSDK) {
    return;
  }

  const isReady = await applicationState.isApplicationReady(editorSDK);
  if (!isReady) {
    console.warn('Members Area installation was corrupted so the integrations pages will not be added');
    log('Skipping addApplications as the application is not ready and probably already deleted');
    return;
  }
  const applicationDefinitions = await getAppDefinitions({ applications, editorSDK });

  return addApplications({ editorSDK, applications: applicationDefinitions, shouldNavigate, biData: options?.biData });
};

const refreshPageState = (editorSDK: EditorSDK) => pagesService.setStateForPages(editorSDK);

export const createPublicApi = (editorSDK: EditorSDK) => {
  const transaction =
    <T extends any[], G>(action: (...args: T) => G) =>
    (...props: T) =>
      runAndWaitForApproval<G>(editorSDK, () => action(...props));

  const sequentialMonitoredTransaction = (name: string, action: () => unknown) => {
    return enforceSequentiality(name, () => toMonitored(name, transaction(action)));
  };

  return {
    addApplications: (applications: IntegrationApplication[], shouldNavigate: boolean, options: { biData: BiData }) =>
      sequentialMonitoredTransaction('editorApi.addApplications', () =>
        maybeAddApplications(applications, shouldNavigate, options),
      ),
    getMembersPageRef: (page: { appDefinitionId: string; appPageId: string }) => {
      if (!page) {
        return;
      }
      const { appDefinitionId, appPageId } = page;
      return routersWrapper.findPageRefByAppData(editorSDK, appDefinitionId, appPageId);
    },
    removeMembersAreaPage: (pageId: string, appDefinitionId: string) =>
      sequentialMonitoredTransaction('editorApi.removeMembersAreaPage', () =>
        removeMembersAreaPageByPageId({ pageId, appDefinitionId, editorSDK }),
      ),
    setHorizontalLayout: () =>
      sequentialMonitoredTransaction('editorApi.setHorizontalLayout', () => setHorizontalLayout(editorSDK)),
    setSidebarLayout: () =>
      sequentialMonitoredTransaction('editorApi.setSidebarLayout', () => setSidebarLayout(editorSDK)),
    _getIsResponsiveEditor: () =>
      enforceSequentiality('_getIsResponsiveEditor', async () => appState.getIsResponsiveEditor()),
    handleVerticalDeletion: (verticalAppDefId: string) =>
      sequentialMonitoredTransaction('editorApi.handleVerticalDeletion', () =>
        membersIntegrationApi.handleVerticalDeletion(verticalAppDefId, editorSDK),
      ),
    registerMembersAreaApps: (
      applications: IntegrationApplication[],
      verticalAppDefId: string,
      applicationsOptions: MembersAreaAppsOptions,
    ) => membersIntegrationApi.registerMembersAreaApps(applications, verticalAppDefId, editorSDK, applicationsOptions),
    installRegisteredApps: (verticalAppDefId: string, options: { biData?: { origin?: string } }) =>
      sequentialMonitoredTransaction('editorApi.installRegisteredApps', () =>
        membersIntegrationApi.installRegisteredApps(verticalAppDefId, editorSDK, options),
      ),
    getRegisteredApps: () => membersIntegrationApi.getRegisteredApps(editorSDK),
    addCustomPage: (isPrivate: boolean) =>
      sequentialMonitoredTransaction('editorApi.createBlankPage', () => createBlankPage(editorSDK, isPrivate)),
    refreshPageState: () => refreshPageState(editorSDK),
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (type: ProfileType.BWP_ALL | ProfileType.BWP_ONLY) =>
      sequentialMonitoredTransaction('editorApi.setProfileType', () => setProfileType(editorSDK, type)),
    refreshRouters: () => routersWrapper.refreshRouters(editorSDK),
    refreshMembersAreaApps: () => refreshMembersAreaApps(editorSDK),
    registerAdditionalWidgets: () => {},
    getAdditionalWidgets: () => {},
    installAdditionalWidgets: () => {},
  };
};
